import clsx from 'clsx';
import CLPReviewBgIllustration from './CLPReviewBgIllustration';
import CLPReviewFooter from './CLPReviewFooter';
import CLPReviewHeader from './CLPReviewHeader';
import CLPReviewQuestionsList from './CLPReviewQuestionsList';
import { ArrowRight } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
}

const CLPReview = (props: Props) => {
  const { className } = props;
  const {
    whyHQ: { GetInTouch },
  } = useStrapiCLPData();
  return (
    <section className={clsx('relative flex flex-col items-center gap-14', className)}>
      <CLPReviewBgIllustration className="absolute bottom-0 left-0" />
      <CLPReviewHeader className="relative max-w-2xl" />
      <CLPReviewQuestionsList className="relative" />
      <CLPReviewFooter className="relative" />
      {GetInTouch.isActive && (
        <Link
          className="group z-10 flex items-center gap-2 rounded-lg border border-base-brand bg-base-brand py-3 px-8 text-base font-semibold text-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)]"
          to={GetInTouch.link}
        >
          <span>{GetInTouch.label}</span>
          <ArrowRight
            size={16}
            weight="bold"
            color="currentColor"
            className="text-inherit transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
            aria-hidden={true}
          />
        </Link>
      )}
    </section>
  );
};

export default CLPReview;
