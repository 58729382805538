import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

const CLPAboutHQIllustration = () => {
  const { aboutHQ } = useStrapiCLPData();
  const imageSrc = aboutHQ?.CoverImage?.data?.attributes?.url || '';

  return aboutHQ ? (
    <div
      className="h-[445px] w-full border-b border-zinc-300"
      style={{
        background: `linear-gradient(0deg, rgba(55, 153, 14, 0.25), rgba(55, 153, 14, 0.25)), linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${imageSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    ></div>
  ) : null;
};

export default CLPAboutHQIllustration;
