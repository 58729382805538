import React from 'react';

interface Props {
  heading: string;
}

const CLPAboutHQContentItem = (props: React.PropsWithChildren<Props>) => {
  const { heading, children } = props;

  return (
    <div className={'flex flex-col gap-5'}>
      <h2 className="text-2xl font-bold leading-7 text-black">{heading}</h2>
      <div className="text-lg font-normal text-[#3D3001]">{children}</div>
    </div>
  );
};

export default CLPAboutHQContentItem;
