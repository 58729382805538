import React from 'react';
import Footer from '../../features/publicSite/components/common/Footer/Footer';
import Navbar from '../Navbar';

const PageWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="h-fit w-full">
      <header className="sticky top-0 z-50 w-full bg-white">
        <Navbar variant="light" />
      </header>
      <main id="main-content">{children}</main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default PageWrapper;
