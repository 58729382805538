import { Outlet } from 'react-router-dom';
import LandingNotFound from '../../../../components/404Pages/LandingNotFound';
import { usePublicSiteData } from '../../hooks/usePublicSiteData';

export const ShowHQPlusLaunch = () => {
  const {
    HqPlusLaunchPagefeaturedflag: { showHqPlusLaunch },
  } = usePublicSiteData();
  if (showHqPlusLaunch) {
    return <Outlet></Outlet>;
  } else {
    return <LandingNotFound></LandingNotFound>;
  }
};
