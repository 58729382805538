import FooterCard from './FooterCard';
import { LinkedinLogo, TwitterLogo } from 'phosphor-react';
import { usePublicSiteData } from '../../../hooks/usePublicSiteData';
import { HospitalityQuotientLogo } from '../../../../../components/Logos';

const Footer = () => {
  const { footerPage } = usePublicSiteData();
  const {
    footerLists,
    allRightsReserved,
    // instagramLink,
    linkedInLink,
    twitterLink,
    hqLogoSRLabel,
    // instagramLinkSRLabel,
    linkedInLinkSRLabel,
    twitterLinkSRLabel,
  } = footerPage;

  const year = new Date();
  const finalAllRightReserved = allRightsReserved.replace('${Year}', year.getFullYear());

  return (
    <div className="flex h-fit w-full flex-col items-center  self-center bg-zinc-900 pb-20 text-center">
      <div className=" mr-36 grid grid-cols-1 justify-between border-white  bg-zinc-900 pt-20 md:mr-0  md:grid-cols-2  md:gap-24  lg:grid-cols-3 xl:gap-44">
        {footerLists.map((footer: any, index: any) => {
          return <FooterCard key={index} footer={footer} />;
        })}
      </div>
      <div className="flex  w-full flex-row justify-center pt-20 pb-6">
        <span className="sr-only">{hqLogoSRLabel}</span>
        <HospitalityQuotientLogo className="h-8 w-auto" variant={'dark'} role="presentation" />
      </div>
      <div className="w-full">
        <p className="text-base font-normal leading-7 text-white bg-blend-normal">
          {finalAllRightReserved}
        </p>
      </div>
      <div className="mt-5 flex flex-row justify-center gap-6">
        <a href={twitterLink} rel="nop noreferrer" target="_blank">
          <span className="sr-only">{twitterLinkSRLabel}</span>
          <TwitterLogo size={24} fill="white" color="white" weight="fill" role="presentation" />
        </a>
        {/* <a href={instagramLink} rel="nop noreferrer" target="_blank">
          <span className="sr-only">{instagramLinkSRLabel}</span>
          <InstagramLogo
             size={24}
             fill="white"
             color="white"
             weight="regular"
             role="presentation"
          />
	      </a> */}
        <a href={linkedInLink} target="_blank" rel="noreferrer">
          <span className="sr-only">{linkedInLinkSRLabel}</span>
          <LinkedinLogo size={24} fill="white" color="white" weight="regular" role="presentation" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
