import Navbar from '../../../../../components/Navbar/Navbar';
import { NavItemMetadata } from '../../../../../types';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';

type ActionItemMetadata = NavItemMetadata;

type CLPHeroActionItemButtonProps = Omit<ActionItemMetadata, 'isActive'>;

const CLPHeroActionItemButton = (props: CLPHeroActionItemButtonProps) => {
  const { isExternal, label, to } = props;

  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      className="group flex items-center gap-1 rounded border border-zinc-200 bg-white p-[21px_25px] text-center text-sm font-medium leading-4 text-base-brand"
    >
      <span>{label}</span>
      <span
        aria-hidden="true"
        className="relative whitespace-nowrap transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
      >
        {'->'}
      </span>
    </Navbar.NavItem>
  );
};

const CLPHeroActionItems = () => {
  const {
    landingPage,
    featureFlag: { showHQPlus },
  } = useStrapiCLPData();
  const { Buttons: actionItems } = landingPage;

  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      {actionItems.map(
        (actionItem: {
          label: string;
          isActive: boolean;
          isExternal: boolean;
          link: string;
          slug: string;
        }) => {
          if (
            (actionItem.slug === 'explore_virtual_learning' && !showHQPlus) ||
            !actionItem.isActive
          ) {
            return null;
          }
          return (
            <CLPHeroActionItemButton
              key={actionItem.slug}
              to={actionItem.link}
              {...actionItem}
            ></CLPHeroActionItemButton>
          );
        }
      )}
    </div>
  );
};

export default CLPHeroActionItems;
