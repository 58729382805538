import clsx from 'clsx';
import CLPAboutHQContent from './CLPAboutHQContent';
import CLPAboutHQIllustration from './CLPAboutHQIllustration';

interface Props {
  className?: string;
}

const CLPAboutHQ = (props: Props) => {
  const { className } = props;

  return (
    <section
      className={clsx(
        'relative mb-[var(--content-y-translate)] flex w-full flex-col items-center pb-9 [--content-y-translate:-152px]',
        className
      )}
    >
      <CLPAboutHQIllustration />
      <div className="max-w-[var(--max-layout-width)] translate-y-[var(--content-y-translate)] px-[var(--common-x-padding)]">
        <CLPAboutHQContent />
      </div>
    </section>
  );
};

export default CLPAboutHQ;
