import clsx from 'clsx';

interface Props {
  label: string;
  className?: string;
}

const CLPReviewQuestionItem = (props: Props) => {
  const { label, className } = props;

  return (
    <div
      className={clsx(
        'rounded-lg px-7 py-10 text-center text-xl font-medium md:py-6 md:px-4 lg:py-9 lg:px-6 xl:py-10 xl:px-7',
        className
      )}
    >
      {label}
    </div>
  );
};

export default CLPReviewQuestionItem;
