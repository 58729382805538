import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { NavItemMetadata } from '../../types';

type Props = Omit<NavItemMetadata, 'isActive' | 'slug' | 'label'> & {
  variant?: 'light' | 'dark';
  className?: string;
  isNavLink?: boolean;
  // State to pass to Link/NavLink
  state?: {
    preventScrollToTop?: boolean;
  };
};

const NavItem = (props: React.PropsWithChildren<Props>) => {
  const {
    isExternal,
    children,
    to,
    variant = 'light',
    className,
    isNavLink = false,
    state,
  } = props;

  const defaultClassName = twMerge(
    clsx(
      'text-base',
      variant === 'light' && 'font-medium text-zinc-900',
      variant === 'dark' && 'font-normal text-white',
      className
    )
  );
  if (isExternal) {
    return (
      <a href={to} className={defaultClassName}>
        {children}
      </a>
    );
  }

  const LinkComponent = isNavLink ? NavLink : Link;

  return (
    <LinkComponent to={to} className={defaultClassName} state={state}>
      {children}
    </LinkComponent>
  );
};

export default NavItem;
