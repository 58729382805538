import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../publicSite/components/common/Footer/Footer';
import CLPAboutHQ from './CLPAboutHQ';
import CLPHero from './CLPHero';
import CLPReview from './CLPReview';
import CLPServices from './CLPServices';
import CLPNewsletterDetail from './CLPNewsLetterDetail/CLPNewsletterDetail';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import { useStrapiCLPData } from '../hooks/useStrapiCLPData';

const CombinedLandingPage = () => {
  const { clpSEO } = useStrapiCLPData();
  return (
    <div className="mx-auto flex flex-col items-center bg-white [--max-layout-width:1792px] [--common-x-padding:26px] xs:[--common-x-padding:52px] xl:[--common-x-padding:116px]">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {clpSEO && <SEOHelmetWrapper seo={clpSEO} />}
      <header className="fixed top-0 z-50 w-full bg-white">
        <Navbar variant="light" />
      </header>
      <main className="flex w-full flex-col items-center" id="main-content">
        <CLPHero className="max-w-[var(--max-layout-width)] pt-36 sm:pt-20" />
        <CLPReview className="max-w-[var(--max-layout-width)] overflow-hidden px-[var(--common-x-padding)] py-16 md:py-20 lg:py-32" />
        <CLPAboutHQ />
        <CLPServices className="w-full max-w-[var(--max-layout-width)] py-16 px-[var(--common-x-padding)] md:py-20" />
        <CLPNewsletterDetail className="w-full max-w-[var(--max-layout-width)] px-[var(--common-x-padding)] py-12 md:py-16 lg:py-20" />
      </main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default CombinedLandingPage;
