import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export interface Props {
  slug: string;
  className?: string;
  Icon: React.ReactNode;
  ActionItem: React.ReactNode;
  heading: string;
  illustration: {
    src: string;
    alt: string;
  };
  supportText: string;
  isActive: boolean;
  contentContainerClassName?: string;
  textContentContainerClassName?: string;
}

const CLPServicesGridItem = (props: Props) => {
  const {
    className,
    heading,
    supportText,
    illustration,
    Icon,
    ActionItem,
    contentContainerClassName,
    textContentContainerClassName,
  } = props;

  return (
    <article className={twMerge(clsx('flex w-full flex-col gap-10', className))}>
      <div className={clsx('flex flex-col items-start gap-8', contentContainerClassName)}>
        <div className={clsx('flex flex-col items-start gap-4', textContentContainerClassName)}>
          {Icon}
          <h3 className="text-3xl font-bold	leading-[2.375rem] text-zinc-900">{heading}</h3>
          <span className="text-lg font-normal text-zinc-500">{supportText}</span>
        </div>
        {ActionItem}
      </div>
      <div className="w-full overflow-hidden rounded-[0.625rem] border border-zinc-300">
        <img
          src={illustration.src}
          alt={illustration.alt}
          className="h-[285px] w-full object-cover"
        ></img>
      </div>
    </article>
  );
};

export default CLPServicesGridItem;
