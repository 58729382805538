import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

const CLPServicesHeading = () => {
  const {
    ourServices: { title, description, OurServiceTitle },
  } = useStrapiCLPData();
  return (
    <header className="flex flex-col items-center gap-5 text-center">
      <div className="flex flex-col gap-3">
        <span className="text-base font-semibold text-base-brand">{OurServiceTitle}</span>
        <h2 className="text-4xl font-bold leading-[2.75rem] tracking-tight text-zinc-900">
          {title}
        </h2>
      </div>
      <span className="max-w-3xl text-xl font-normal leading-[1.875rem] text-zinc-600">
        {description}
      </span>
    </header>
  );
};

export default CLPServicesHeading;
