import React from 'react';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';

const CLPHeroSubHeading = () => {
  const {
    landingPage: { Slogan: slogans },
  } = useStrapiCLPData();
  const mantras = ['Learn', 'Share', 'Collaborate'];
  const separator = '&#183;';

  return (
    <div className="flex items-center gap-1 text-xs font-medium leading-6 text-zinc-700 opacity-75 xs:text-[0.9375rem]">
      {slogans.map((mantra: { mantra: string }, idx: number) => {
        let insertSeparator = false;

        if (idx !== mantras.length - 1) {
          insertSeparator = true;
        }

        return (
          <React.Fragment key={mantra.mantra}>
            <span>{mantra.mantra}</span>
            {insertSeparator ? <span dangerouslySetInnerHTML={{ __html: separator }}></span> : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CLPHeroSubHeading;
