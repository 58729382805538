import clsx from 'clsx';
import { CheckCircle } from 'phosphor-react';
import ReactMarkdown from 'react-markdown';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import CLPAboutHQContentItem from './CLPAboutHQContentItem';

interface Props {
  className?: string;
}

const CLPAboutHQContent = (props: Props) => {
  const { className } = props;
  const { aboutHQ } = useStrapiCLPData();
  const {
    WhatIsHQ: { description: whatIsHQDescription = '', title: whatIsHQTitle = '' } = {},
    GoldenRules: {
      title: goldenRulesHeading = '',
      description: golderRulesDescription = '',
      Rules: goldenRules = [],
    } = {},
  } = aboutHQ || {};

  return aboutHQ ? (
    <section
      className={clsx(
        'flex flex-col gap-10 rounded-2xl border border-zinc-300 bg-[#FAF7E9] p-7 text-start sm:py-8 sm:px-9 md:py-[36px] md:px-[48px] lg:py-[49px] lg:px-[58px] xl:grid xl:grid-cols-2 xl:grid-rows-1 xl:gap-16',
        className
      )}
    >
      {/* What is HQ */}
      <CLPAboutHQContentItem heading={whatIsHQTitle}>
        <ReactMarkdown>{whatIsHQDescription}</ReactMarkdown>
      </CLPAboutHQContentItem>
      {/* Golden Rules */}
      <CLPAboutHQContentItem heading={goldenRulesHeading}>
        <p className="mb-4">{golderRulesDescription}</p>
        <ul className="grid auto-rows-auto grid-cols-1 gap-4">
          {goldenRules?.map((rule: { rule: string }) => {
            return (
              <li key={rule.rule}>
                <div className="flex items-start gap-3">
                  <div className="flex h-7 shrink-0 items-center justify-center">
                    <CheckCircle
                      className="text-base-green"
                      color="currentColor"
                      size={28}
                      role="presentation"
                    />
                  </div>
                  <p>{rule.rule}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </CLPAboutHQContentItem>
    </section>
  ) : null;
};

export default CLPAboutHQContent;
