import { ArrowRight } from 'phosphor-react';
import Navbar from '../../../../components/Navbar';

import Footer from '../common/Footer/Footer';

import { INTERNAL_SITE_ROUTES } from '../../../../constants/internal-site-routes';
import { usePublicSiteData } from '../../hooks/usePublicSiteData';
import SizzleVideoDisplay from './SizzleVideoDisplay';

const SizzleVideoPage = () => {
  const { HqPlusLaunchPageSizzleVideo, HqPlusLaunchLandingPage } = usePublicSiteData();

  return (
    <div className="relative h-max w-full bg-zinc-50">
      <div className="absolute h-[20rem] w-full border-b border-zinc-800 bg-zinc-900 lg:h-[25rem] 2xl:h-[40rem]"></div>
      <header className="sticky top-0 z-50">
        <Navbar
          showAuthActions={true}
          variant="dark"
          showUSHGLogo={false}
          className="bg-zinc-900"
        />
      </header>
      <main className="relative w-full" id="main-content">
        <div className="relative">
          <div className="flex h-max flex-col p-5 md:px-12 lg:px-[8.5rem]">
            <div className="flex flex-col justify-end gap-4 md:flex-row">
              <a
                href={INTERNAL_SITE_ROUTES.SUBSCRIPTION}
                className="group flex h-max w-max items-center justify-center gap-2.5 rounded-md bg-base-brand px-[24px] py-4 text-[1.125rem] font-semibold leading-5 text-white"
              >
                <span className="whitespace-nowrap">
                  {HqPlusLaunchLandingPage?.subscribeNowButton}
                </span>
                <ArrowRight
                  weight="bold"
                  color="currentColor"
                  className="text-inherit transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
                  aria-hidden="true"
                />
              </a>
            </div>
            {/* Page Content */}
            <SizzleVideoDisplay SizzleVideo={HqPlusLaunchPageSizzleVideo} />
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default SizzleVideoPage;
