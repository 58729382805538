import clsx from 'clsx';
import CLPHeroContent from './CLPHeroContent';
import CLPHeroIllustration from './CLPHeroIllustration';

interface Props {
  className?: string;
}

const CLPHero = ({ className }: Props) => {
  return (
    <section
      className={clsx(
        'flex w-full flex-col justify-between gap-2 overflow-hidden xs:gap-7 sm:flex-row sm:gap-14',
        className
      )}
    >
      <CLPHeroContent className="px-9" />
      <CLPHeroIllustration className="aspect-square w-full sm:h-[669px] sm:w-[669px]" />
    </section>
  );
};

export default CLPHero;
