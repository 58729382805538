import { VideoController } from '../../../../components/MediaControls';

export interface SizzleVideoDisplayProps {
  SizzleVideo: any;
}

const SizzleVideoDisplay = ({ SizzleVideo }: SizzleVideoDisplayProps) => {
  const settings = {
    autoplay: true,
    muted: true,
    loop: true,
  };

  return (
    <div className="flex flex-col gap-8">
      {/* Video */}
      <div className="mt-8 w-full rounded">
        <VideoController
          className="aspect-video w-full bg-black object-contain sm:aspect-[3/1.2]"
          thumbnail={SizzleVideo.sizzleVideothumbnail?.data?.attributes?.url}
          video={SizzleVideo.sizzleVideo?.data?.attributes?.url}
          settings={settings}
        />
      </div>
      {/* Video */}
    </div>
  );
};

export default SizzleVideoDisplay;
