import { gql } from '@apollo/client';

export const HQFormFragment = gql`
  fragment HQFormFragment on HqFormEntityResponse {
    data {
      attributes {
        LearnMore {
          Title {
            label
            color
          }
          coverImage {
            data {
              attributes {
                url
              }
            }
          }
          HubspotTicketPriority {
            mediumPriorityRangeValue
            highPriorityRangeValue
          }
          thankYouMessage
          errorMessage
          formDescription
          firstNameLabel
          firstNamePlaceholder
          lastNameLabel
          lastNamePlaceholder
          emailLabel
          emailPlaceholder
          organizationLabel
          organizationPlaceholder
          organizationSizeLabel
          organizationSizePlaceholder
          typeOfEngagementLabel
          typeOfEngagementPlaceholder
          TypeOfEngagementOptions {
            label
            value
          }
          preferredSpeakersLabel
          preferredSpeakersPlaceholder
          jobTitleLabel
          jobTitlePlaceholder
          locationLabel
          locationPlaceholder
          industryLabel
          industryPlaceholder
          IndustryOptions(pagination: { limit: 100 }) {
            label
            value
          }
          howDidYouHearAboutUsLabel
          howDidYouHearAboutUsPlaceholder
          HowDidYouHearAboutUsOptions(pagination: { limit: 100 }) {
            label
            value
          }
          messageLabel
          messagePlaceholder
          contactUsButton
          typeOfConsultingLabel
          typeOfConsultingPlaceholder
          firstNameRequired
          firstNameMinValue
          firstNameMinError
          firstNameMaxError
          firstNameMaxValue
          validFirstName
          namePattern
          lastNameRequired
          lastNameMinValue
          lastNameMinError
          lastNameMaxValue
          lastNameMaxError
          vaildLastName
          emailRequired
          validEmail
          organizationRequired
          organizationSizeRequired
          organizationMinValue
          organizationMinError
          organizationMaxError
          organizationMaxValue
          OrganizationSizeOptions {
            label
            value
          }
          typeOfEngagementRequired
          messageMaxvalue
          messageMaxError
          preferredSpeakersMaxError
          preferredSpeakersMaxValue
          PreferredSpeakers(pagination: { limit: 100 }) {
            speakerName
          }
          TypeOfConsulting {
            programName
          }
          Consent {
            paragraph
            processConsent
            communicationConsent
            subscriptionTypeId
          }
          consentRequired
          Seo {
            pageTitle
            pageDescription
            ogTitle
            ogDescription
            ogSiteName
            canonicalUrl
            twitterTitle
            twitterDescription
            twitterUsername
            socialMediaImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            ogType
            twitterCardType
            preventIndexing
          }
        }
        Subscription {
          Title {
            label
            color
          }
          thankYouMessage
          errorMessage
          formDescription
          firstNameLabel
          firstNamePlaceholder
          lastNameLabel
          lastNamePlaceholder
          emailLabel
          emailPlaceholder
          jobTitleLabel
          jobTitlePlaceholder
          industryLabel
          industryPlaceholder
          IndustryOptions(pagination: { limit: 100 }) {
            label
            value
          }
          interestedInLabel
          interestedInPlaceholder
          InterestedInOptions(pagination: { limit: 100 }) {
            label
            value
          }
          companyNameLabel
          companyNamePlaceholder
          signUpButton
          firstNameRequired
          firstNameMinValue
          firstNameMinError
          firstNameMaxError
          firstNameMaxValue
          validFirstName
          namePattern
          lastNameRequired
          lastNameMinValue
          lastNameMinError
          lastNameMaxValue
          lastNameMaxError
          vaildLastName
          emailRequired
          validEmail
          companyNameRequired
          companyNameMinValue
          companyNameMinError
          companyNameMaxError
          companyNameMaxValue
          Consent {
            paragraph
            processConsent
            communicationConsent
            subscriptionTypeId
          }
          consentRequired
          Seo {
            pageTitle
            pageDescription
            ogTitle
            ogDescription
            ogSiteName
            canonicalUrl
            twitterTitle
            twitterDescription
            twitterUsername
            socialMediaImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            ogType
            twitterCardType
            preventIndexing
          }
        }
      }
    }
  }
`;
