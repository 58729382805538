import { twMerge } from 'tailwind-merge';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
}

const CLPReviewFooter = (props: Props) => {
  const { className } = props;
  const {
    whyHQ: { answer },
  } = useStrapiCLPData();
  return (
    <div
      className={twMerge(
        'text-center text-[1.625rem] font-semibold leading-8 tracking-[-0.01rem] text-base-brand',
        className
      )}
    >
      {answer}
    </div>
  );
};

export default CLPReviewFooter;
