import clsx from 'clsx';
import { Graph, IconProps, Megaphone, MonitorPlay, UsersThree } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import { ServiceItemStrapi } from '../../../types';
import CLPServicesGridActionItem from './CLPServicesGridActionItem';
import CLPServicesGridItem, { Props as CLPServicesGridItemProps } from './CLPServicesGridItem';
const iconMap: Record<
  string,
  React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
> = {
  // eslint-disable-next-line camelcase
  Monitor: MonitorPlay,
  // eslint-disable-next-line camelcase
  Megaphone: Megaphone,
  // eslint-disable-next-line camelcase
  Graph: Graph,
  // eslint-disable-next-line camelcase
  People: UsersThree,
};
const classNameMap: Record<string, { buttonClassName: string; iconClassName: string }> = {
  Green: {
    buttonClassName: 'border-base-green bg-base-green',
    iconClassName: 'text-base-green',
  },
  // eslint-disable-next-line camelcase
  Bright_Green: {
    buttonClassName: 'border-base-bright-green bg-base-bright-green text-black',
    iconClassName: 'text-base-bright-green',
  },
  Black: {
    buttonClassName: 'border-black bg-black',
    iconClassName: 'text-black',
  },
  Brand: {
    buttonClassName: 'border-base-brand bg-base-brand ',
    iconClassName: 'text-base-brand',
  },
};
const CLPServicesGrid = () => {
  const {
    ourServices: { Services },
    featureFlag: { showHQPlus, lastServiceCardTakesFullWidth },
  } = useStrapiCLPData();

  const serviceGridItems = [...Services].map((service: ServiceItemStrapi) => {
    const { buttonClassName, iconClassName } = classNameMap[service.theme];
    const IconComponent = service.Icon && iconMap[service.Icon];
    const Icon = IconComponent ? (
      <IconComponent
        size={48}
        color="currentColor"
        weight="fill"
        className={iconClassName}
        aria-hidden={true}
      />
    ) : null;
    const slug = service.slug;
    const ActionItem = (
      <CLPServicesGridActionItem
        isActive={service.isActive}
        isExternal={service.isExternal}
        to={service.link}
        label={service.button}
        srLabel={service.buttonSRLabel}
        className={buttonClassName}
      />
    );
    const heading = service.title;
    const src = service.serviceImage.data.attributes.url;
    const alt = service.serviceImage.data.attributes.alternativeText;
    const supportText = service.description;
    let isActive = service.isActive;
    if (service.slug === 'hq_plus') {
      isActive = showHQPlus && service.isActive;
    }
    const isExternal = service.isExternal;
    const to = service.link;
    return {
      Icon,
      ActionItem,
      heading,
      illustration: {
        src,
        alt,
      },
      supportText,
      isActive,
      isExternal,
      to,
      slug,
    };
  });
  const activeServiceGridItems = serviceGridItems.filter(
    (serviceGridItem: CLPServicesGridItemProps) => serviceGridItem.isActive
  );

  const hasOddServiceGridItems = activeServiceGridItems.length % 2 == 1;
  const loneCardIndex = lastServiceCardTakesFullWidth ? activeServiceGridItems.length - 1 : 0;
  return (
    <div
      className={clsx(
        'sm:[--max-service-item-width:100%] md:[--max-service-item-width:596px] lg:[--max-service-item-width:420px] xl:[--max-service-item-width:480px] 2xl:[--max-service-item-width:596px]',
        'grid auto-rows-auto grid-cols-[repeat(auto-fit,minmax(var(--max-service-item-width),1fr))] justify-center gap-y-6 gap-x-10 sm:gap-y-7 md:gap-y-10 lg:gap-y-14 xl:gap-x-[86px]',
        'justify-items-center'
      )}
    >
      {activeServiceGridItems.map((serviceGridItem: CLPServicesGridItemProps, idx: number) => {
        if (!serviceGridItem.isActive) {
          return null;
        }

        let isLoneCard = false;

        // If we have multiple odd items then one would be left out so span the last one two rows
        if (hasOddServiceGridItems && idx === loneCardIndex) {
          isLoneCard = true;
        }

        return (
          <CLPServicesGridItem
            key={serviceGridItem.heading}
            {...serviceGridItem}
            className={clsx(
              'max-w-[var(--max-service-item-width)] py-8 lg:py-10',
              isLoneCard && 'col-span-1 lg:col-span-2'
            )}
            contentContainerClassName={twMerge(
              'items-center text-center lg:items-start',
              hasOddServiceGridItems && 'lg:items-center lg:text-center',
              !hasOddServiceGridItems && 'lg:!text-start'
            )}
            textContentContainerClassName={twMerge(
              'items-center lg:items-start',
              hasOddServiceGridItems && 'lg:items-center'
            )}
          />
        );
      })}
    </div>
  );
};

export default CLPServicesGrid;
