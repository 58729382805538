import { twMerge } from 'tailwind-merge';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
}

const CLPReviewBgIllustration = (props: Props) => {
  const { className } = props;
  const {
    whyHQ: { backgroundImage },
  } = useStrapiCLPData();

  return (
    <div
      className={twMerge('h-[490px] w-[638px]', className)}
      style={{ backgroundImage: `url(${backgroundImage.data.attributes.url})` }}
    ></div>
  );
};

export default CLPReviewBgIllustration;
