import clsx from 'clsx';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
}

const CLPReviewHeader = (props: Props) => {
  const { className } = props;
  const {
    whyHQ: { title },
  } = useStrapiCLPData();
  return (
    <header className={clsx('text-center', className)}>
      <h2 className="text-[2rem] font-bold leading-10 tracking-tight text-zinc-900">{title}</h2>
    </header>
  );
};

export default CLPReviewHeader;
