import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';

interface Props {
  content: string;
}

// Wrapper over TooltipWrapper for VideoController controls to add default props
const VideoControlTooltipWrapper = (props: React.PropsWithChildren<Props>) => {
  const { content, children } = props;

  return (
    <TooltipWrapper
      asChild={true}
      content={content}
      hideContentFromSR={true}
      showArrow={false}
      sideOffset={5}
    >
      <div>{children}</div>
    </TooltipWrapper>
  );
};

export default VideoControlTooltipWrapper;
