import { twMerge } from 'tailwind-merge';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import CLPReviewQuestionItem from './CLPReviewQuestionItem';

interface Props {
  className: string;
}

const CLPReviewQuestionsList = (props: Props) => {
  const { className } = props;
  const {
    whyHQ: { WhyUsCard },
  } = useStrapiCLPData();
  const classNameMap: Record<string, string> = {
    Pink: 'text-[#652820] bg-[#FEEFED] border border-[#FBB7AE]',
    Green: 'text-[#163E06] bg-[#EBF5E7] border border-[#A3D090]',
    Creme: 'text-[#53321D] bg-[#FAF7E9] border border-[#E8D89D]',
  };
  const questionsList = WhyUsCard.map((card: { question: string; cardTheme: string }) => {
    const label = card.question;
    const className = classNameMap[card.cardTheme];
    return { label, className };
  });
  return (
    <div className={twMerge('grid grid-cols-1 gap-4 md:grid-cols-3', className)}>
      {questionsList.map((question: { label: string; className: string }) => {
        return (
          <CLPReviewQuestionItem
            key={question.label}
            label={question.label}
            className={question.className}
          />
        );
      })}
    </div>
  );
};

export default CLPReviewQuestionsList;
