import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import CLPHeroActionItems from './CLPHeroActionItems';
import CLPHeroSubHeading from './CLPHeroSubHeading';

interface Props {
  className?: string;
}

// Content in the Hero Section
const CLPHeroContent = ({ className }: Props) => {
  const {
    landingPage: { Title: titleArray, description: supportText },
  } = useStrapiCLPData();

  const classNameMap: Record<string, string> = {
    // eslint-disable-next-line camelcase
    soft_black: 'text-base-soft-black',
    brand: 'text-base-brand',
    tangerine: 'text-base-tangerine',
    green: 'text-base-green',
    // eslint-disable-next-line camelcase
    bright_green: 'text-base-bright-green',
  };
  const titleParts = titleArray.map((title: { label: string; color: string }) => {
    const label = title.label;
    const color = title.color;
    const className = classNameMap[color];
    return { label, className };
  });

  return (
    <section className={clsx('flex flex-col items-start justify-center gap-8 xs:gap-5', className)}>
      <div className="flex flex-col gap-5">
        <header className="flex flex-col items-start gap-2.5">
          <CLPHeroSubHeading />
          <h1 className="flex flex-wrap items-center gap-1 text-[2.125rem] font-bold leading-[2.5625rem] tracking-tight xs:gap-2.5 xs:text-[3.125rem] xs:leading-[3.125rem]">
            {titleParts.map((titlePart: { label: string; className: string }, idx: number) => {
              let addSpace = false;
              if (idx !== titleParts.length - 1) {
                addSpace = true;
              }

              return (
                <span
                  key={titlePart.label}
                  className={clsx('sm:whitespace-nowrap', titlePart.className)}
                >{`${titlePart.label}${addSpace ? ' ' : ''}`}</span>
              );
            })}
          </h1>
        </header>
        <div className="flex flex-col gap-3 text-base font-medium text-base-soft-black opacity-75 xs:text-[1.375rem] xs:leading-[1.875rem]">
          <ReactMarkdown>{supportText}</ReactMarkdown>
        </div>
      </div>
      <CLPHeroActionItems />
    </section>
  );
};

export default CLPHeroContent;
