import { gql } from '@apollo/client';

export const HqLiveFragment = gql`
  fragment HqLiveFragment on HqliveEntityResponse {
    data {
      attributes {
        CONTACT_US {
          ... on ComponentLandingPageContactUsForm {
            Title {
              label
              color
            }
            coverImage {
              data {
                attributes {
                  url
                }
              }
            }
            HubspotTicketPriority {
              mediumPriorityRangeValue
              highPriorityRangeValue
            }
            thankYouMessage
            errorMessage
            formDescription
            firstNameLabel
            firstNamePlaceholder
            lastNameLabel
            lastNamePlaceholder
            emailLabel
            emailPlaceholder
            organizationLabel
            organizationPlaceholder
            organizationSizeLabel
            organizationSizePlaceholder
            typeOfEngagementLabel
            typeOfEngagementPlaceholder
            preferredSpeakersLabel
            preferredSpeakersPlaceholder
            messageLabel
            messagePlaceholder
            contactUsButton
            typeOfEngagementOptions {
              label
              value
            }
            consultingProgramsLabel
            consultingProgramsPlaceholder
            firstNameRequired
            firstNameMinValue
            firstNameMinError
            firstNameMaxError
            firstNameMaxValue
            validFirstName
            lastNameRequired
            lastNameMinValue
            lastNameMinError
            lastNameMaxValue
            lastNameMaxError
            vaildLastName
            emailRequired
            validEmail
            organizationRequired
            organizationSizeRequired
            typeOfEngagementRequired
            messageMaxvalue
            messageMaxError
            preferredSpeakersMaxError
            preferredSpeakersMaxValue
          }
        }

        SPEAKERS {
          ... on ComponentLandingPageWorkshopSpeakers {
            Title {
              label
              color
            }
            speakerTopicLabel
            speakersTitle
            description
            contactUsButton
            inquireLabel
            learnMoreLabel
            learnMoreLink
            workshopSpeakerCard(pagination: { limit: 100 }) {
              SpeakerTopics {
                topic
              }
              speakerName
              speakerDesignation
              speakerDescription
              numberOfCourses
              isFounder
              coursesLabel
              speakerImage {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        OUR_PROGRAMS {
          ... on ComponentLandingPageOurPrograms {
            title
            description
            programs {
              programTitle
              theme
              programDescription
              seatingCapacityDescription
              programPrice
              bookASessionButton
              currencySymbol
              isImageFirst
              programFeatures {
                Listitem
              }
              slug
              programImage {
                data {
                  attributes {
                    url
                  }
                }
              }
              programIcon {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        LANDING_PAGE {
          ... on ComponentHqliveLandingPage {
            Title {
              label
              color
            }
            description
            Button {
              isExternal
              label
              link
            }
            CoverImage {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
        IN_PERSON_WORKSHOP {
          ... on ComponentInpersonWorkshopTopNav {
            title
            description
            learnMoreButton
            learnMoreButtonSRLabel
          }
          ... on ComponentInpersonWorkshopWorkshop {
            WorkshopList {
              workshopTitle
              upcomingLabel
              workshopFeaturesListTitle
              reserveSpotButton
              workshopDescription
              workshopCoverImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              fromDate
              toDate
              workshopDaysCount
              eventLabel
              bookingLink
              perPersonLabel
              purchaseLimitMessage
              location
              attendeeRange
              speakerTitle
              Speakers {
                speakerName
                speakerRole
                speakerImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              DayWiseActivitiesForWorkhop {
                day
                activity
              }
              WorkhopFeatures {
                feature
              }
              OutercardWorkshopFeatures {
                feature
              }
              outerCardWorkshopFeaturesListTitle
              outerCardWorkshopFeaturesNote
              price
            }
          }
          ... on ComponentLandingPagePreviousWorkshops {
            previousWorkshopsTitle
            previousWorkshopsHeader
            previousWorkshopsDescription
            previousWorkshopsImages {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
