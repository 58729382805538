import { ArrowRight } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

type props = {
  className?: string;
};

const CLPNewsletterDetail = ({ className }: props) => {
  const {
    newsletterSection: { title, button, description },
  } = useStrapiCLPData();
  return (
    <div
      className={twMerge(
        className,
        'flex flex-col items-center justify-around bg-base-creme md:flex-row'
      )}
    >
      <div className="m-3 flex flex-col gap-3 text-center md:text-start">
        <h2 className="text-3xl font-medium leading-[2.75rem] tracking-[-0.072px] text-gray-900">
          {title}
        </h2>
        <p className="text-lg font-normal leading-7 text-gray-600">{description}</p>
      </div>
      <Link
        className="group m-3 flex h-min shrink-0 items-center gap-2 rounded-lg border border-base-brand bg-base-brand py-3 px-6 text-base font-semibold text-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)]"
        to={button.link}
      >
        <span>{button.label}</span>
        <ArrowRight
          size={16}
          weight="bold"
          color="currentColor"
          className="text-inherit transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
          aria-hidden={true}
        />
      </Link>
    </div>
  );
};

export default CLPNewsletterDetail;
