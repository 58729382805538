import * as Tooltip from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';

type tooltipProps = {
  content: string;
  subContent?: string;
  ariaLabel?: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  alignment?: 'start' | 'center' | 'end';
  duration?: number;
  // Whether to hide the tooltip content from SR
  hideContentFromSR?: boolean;
  asChild?: boolean;
  theme?: 'dark' | 'light';
  showArrow?: boolean;
  sideOffset?: number;
};

const TooltipWrapper = (props: React.PropsWithChildren<tooltipProps>) => {
  const {
    content,
    direction,
    ariaLabel,
    alignment,
    duration,
    subContent,
    children,
    asChild = false,
    theme = 'dark',
    showArrow = true,
    sideOffset,
    hideContentFromSR = false,
  } = props;

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={duration ?? 800}>
        <Tooltip.Trigger asChild={asChild}>{children}</Tooltip.Trigger>
        <Tooltip.Content
          side={direction ?? 'top'}
          align={alignment ?? 'center'}
          aria-label={ariaLabel}
          aria-hidden={hideContentFromSR}
          className="flex flex-col whitespace-normal"
          sideOffset={sideOffset}
        >
          {showArrow && (
            <Tooltip.Arrow
              fill="currentColor"
              className={twMerge(
                theme === 'dark' && 'text-zinc-900',
                theme === 'light' && 'text-zinc-100'
              )}
            ></Tooltip.Arrow>
          )}
          <div
            className={twMerge(
              'w-max rounded p-2 text-xs font-medium',
              theme === 'dark' && 'bg-zinc-900 text-zinc-100',
              theme === 'light' && 'bg-zinc-100 text-zinc-900'
            )}
          >
            <div> {content}</div>
            {subContent && <div>{subContent}</div>}
          </div>
        </Tooltip.Content>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default TooltipWrapper;
