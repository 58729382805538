import { RootState } from '../../../store';
import { useAppSelector } from '../../../hooks';

const strapiDataSelector = (state: RootState) => state.strapi.publicStrapiData;

export const usePublicSiteData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const strapiPublicSiteFooter = data.footer.data.attributes;
  const strapiPublicSiteLandingPage = data.landingpage.data.attributes.landingPage;
  const privacyPolicyPage = data.privacyPolicyRichText.data.attributes;
  const termsAndConditionPage = data.termsAndCondition.data.attributes;
  let landingPage;
  let handPickedFvPage;
  let speakerPage;
  let testimonialPage;
  let whyUsPage;
  let pricingPage;
  let ctaPage;
  let landingPageSEO;
  const footerPage = strapiPublicSiteFooter;
  for (let i = 0; i <= strapiPublicSiteLandingPage?.length; i++) {
    if (strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPageLandingPage') {
      landingPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (
      strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPageHandPickedFavorites'
    ) {
      handPickedFvPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (
      strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPageSpeakerSpotlight'
    ) {
      speakerPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPageTestimonals') {
      testimonialPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPageFeatures') {
      whyUsPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPagePricingTable') {
      pricingPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (strapiPublicSiteLandingPage[i]?.__typename === 'ComponentLandingPageCta') {
      ctaPage = (strapiPublicSiteLandingPage ?? [])[i];
    } else if (strapiPublicSiteLandingPage[i]?.__typename === 'ComponentSharedSeo') {
      landingPageSEO = (strapiPublicSiteLandingPage ?? [])[i];
    }
  }

  const strapiPublicSiteAboutUs = data.landingpage.data.attributes.AboutUs;
  let aboutUsHomePage;
  let aboutUsFeaturePage;
  let aboutUsStatisticsPage;
  let aboutUsPageSEO;
  for (let i = 0; i <= strapiPublicSiteAboutUs?.length; i++) {
    if (strapiPublicSiteAboutUs[i]?.__typename === 'ComponentLandingPageAboutUsHome') {
      aboutUsHomePage = (strapiPublicSiteAboutUs ?? [])[i];
    } else if (strapiPublicSiteAboutUs[i]?.__typename === 'ComponentLandingPageAboutUsFeature') {
      aboutUsFeaturePage = (strapiPublicSiteAboutUs ?? [])[i];
    } else if (strapiPublicSiteAboutUs[i]?.__typename === 'ComponentLandingPageAboutUsStatistics') {
      aboutUsStatisticsPage = (strapiPublicSiteAboutUs ?? [])[i];
    } else if (strapiPublicSiteAboutUs[i]?.__typename === 'ComponentSharedSeo') {
      aboutUsPageSEO = (strapiPublicSiteAboutUs ?? [])[i];
    }
  }
  const strapiPublicSiteFAQ = data.landingpage.data.attributes.FAQ;
  let FAQPage;
  let getInTouchPage;
  let faqPageSEO;
  for (let i = 0; i <= strapiPublicSiteFAQ?.length; i++) {
    if (strapiPublicSiteFAQ[i]?.__typename === 'ComponentLandingPageFaq') {
      FAQPage = (strapiPublicSiteFAQ ?? [])[i];
    } else if (strapiPublicSiteFAQ[i]?.__typename === 'ComponentLandingPageGetInTouch') {
      getInTouchPage = (strapiPublicSiteFAQ ?? [])[i];
    } else if (strapiPublicSiteFAQ[i]?.__typename === 'ComponentSharedSeo') {
      faqPageSEO = (strapiPublicSiteFAQ ?? [])[i];
    }
  }
  const strapiPublicSiteCourses = data.landingpage.data.attributes.Courses;
  let CoursesPage;
  let coursesPageSEO;
  for (let i = 0; i <= strapiPublicSiteCourses?.length; i++) {
    if (strapiPublicSiteCourses[i]?.__typename === 'ComponentLandingPageCoursesHome') {
      CoursesPage = (strapiPublicSiteCourses ?? [])[i];
    } else if (strapiPublicSiteCourses[i]?.__typename === 'ComponentSharedSeo') {
      coursesPageSEO = (strapiPublicSiteCourses ?? [])[i];
    }
  }
  const strapiPublicSiteWorkshops = data.landingpage.data.attributes.Workshops;
  let CustomizePage;
  let PreviousWorkshopPage;
  let WorkshopSpeaker;
  let contactUsForm;
  let ourProgram;
  for (let i = 0; i <= strapiPublicSiteWorkshops?.length; i++) {
    if (strapiPublicSiteWorkshops[i]?.__typename === 'ComponentLandingPageCustomizeYourProgram') {
      CustomizePage = (strapiPublicSiteWorkshops ?? [])[i];
    } else if (
      strapiPublicSiteWorkshops[i]?.__typename === 'ComponentLandingPagePreviousWorkshops'
    ) {
      PreviousWorkshopPage = (strapiPublicSiteWorkshops ?? [])[i];
    } else if (
      strapiPublicSiteWorkshops[i]?.__typename === 'ComponentLandingPageWorkshopSpeakers'
    ) {
      WorkshopSpeaker = (strapiPublicSiteWorkshops ?? [])[i];
    } else if (strapiPublicSiteWorkshops[i]?.__typename === 'ComponentLandingPageContactUsForm') {
      contactUsForm = (strapiPublicSiteWorkshops ?? [])[i];
    } else if (strapiPublicSiteWorkshops[i]?.__typename === 'ComponentLandingPageOurPrograms') {
      ourProgram = (strapiPublicSiteWorkshops ?? [])[i];
    }
  }
  const brandLogo = data.sideNavigator.data.attributes.logo.data.attributes.url;

  const strapiPublicSiteHqPLusLaunch = data.landingpage.data.attributes.HqPlusLaunch;
  let HqPlusLaunchPageSizzleVideo;
  let HqPlusLaunchPagefeaturedflag;
  let HqPlusLaunchLandingPage;

  for (let i = 0; i <= strapiPublicSiteHqPLusLaunch?.length; i++) {
    if (strapiPublicSiteHqPLusLaunch[i]?.__typename === 'ComponentHqplusSizzleVideo') {
      HqPlusLaunchPageSizzleVideo = (strapiPublicSiteHqPLusLaunch ?? [])[i];
    } else if (strapiPublicSiteHqPLusLaunch[i]?.__typename === 'ComponentHqplusFeaturedFlag') {
      HqPlusLaunchPagefeaturedflag = (strapiPublicSiteHqPLusLaunch ?? [])[i];
    } else if (strapiPublicSiteHqPLusLaunch[i]?.__typename === 'ComponentHqplusLandingPage') {
      HqPlusLaunchLandingPage = (strapiPublicSiteHqPLusLaunch ?? [])[i];
    }
  }
  return {
    brandLogo,
    footerPage,
    landingPage,
    handPickedFvPage,
    speakerPage,
    testimonialPage,
    whyUsPage,
    ctaPage,
    landingPageSEO,
    aboutUsHomePage,
    aboutUsFeaturePage,
    aboutUsStatisticsPage,
    aboutUsPageSEO,
    FAQPage,
    getInTouchPage,
    faqPageSEO,
    pricingPage,
    CoursesPage,
    coursesPageSEO,
    CustomizePage,
    PreviousWorkshopPage,
    WorkshopSpeaker,
    contactUsForm,
    ourProgram,
    privacyPolicyPage,
    termsAndConditionPage,
    HqPlusLaunchPageSizzleVideo,
    HqPlusLaunchPagefeaturedflag,
    HqPlusLaunchLandingPage,
  };
};
