import { twMerge } from 'tailwind-merge';
import { useStrapiCLPData } from '../../features/combined-landing-page/hooks/useStrapiCLPData';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import { NavItemMetaStrapiData } from '../../types';
import NavItem from './NavItem';
import { useEffect, useState } from 'react';
import DropDownMenu from '../DropDown';

interface Props {
  className?: string;
  variant: 'light' | 'dark';
}

const DesktopNavMenu = ({ className, variant }: Props) => {
  const data: any = useStrapiDataHelper();
  const strapiNavData = data?.nav.data.attributes;
  const { PublicSiteNavBar: navItems } = strapiNavData;
  const {
    featureFlag: { showHQPlus, showAboutUs },
  } = useStrapiCLPData();
  const [primaryItems, setPrimaryItems] = useState<NavItemMetaStrapiData[]>([]);
  const [dropItems, setDropItems] = useState<any>({});
  useEffect(() => {
    if (navItems) {
      const primary: NavItemMetaStrapiData[] = [];
      const drop: any = {};
      navItems.forEach((item: NavItemMetaStrapiData) => {
        if (item.parentElement === null || item.parentElement === undefined) {
          primary.push(item);
        } else {
          if (!drop[item.parentElement]) {
            drop[item.parentElement] = [item];
          } else {
            drop[item.parentElement] = [...drop[item.parentElement], item];
          }
        }
      });
      setDropItems(drop);
      setPrimaryItems(primary);
    }
  }, [navItems]);

  return (
    <nav className={twMerge('hidden md:block', className)}>
      <ul className="flex flex-row gap-6 md:gap-8">
        {primaryItems.map((navItem: NavItemMetaStrapiData) => {
          const { isActive, isExternal, label, link, slug } = navItem;
          // for hq plus if  isActive = false, we don't want to show the nav item on the screen
          if ((slug === 'hq_plus' && !showHQPlus) || !isActive) {
            return null;
          }
          if ((slug === 'about_us' && !showAboutUs) || !isActive) {
            return null;
          }

          return (
            <li key={slug}>
              <NavItem
                isNavLink={true}
                isExternal={isExternal}
                to={link}
                variant={variant}
                className="whitespace-nowrap"
              >
                {label}
              </NavItem>
            </li>
          );
        })}
        {Object.keys(dropItems).map((parentElement: string) => {
          return (
            <li key={parentElement}>
              <DropDownMenu
                label={parentElement}
                content={dropItems[parentElement]}
                variant={variant}
                className="whitespace-nowrap"
                contentSideOffset={1}
              />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default DesktopNavMenu;
