import { EXTERNAL_SITE_ROUTES } from '../../constants';
import { INTERNAL_SITE_ROUTES } from '../../constants/internal-site-routes';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import NavItem from './NavItem';

const NavAuthActions = () => {
  const data: any = useStrapiDataHelper();
  const strapiNavData = data?.nav.data.attributes;
  const { logInLabel, signUpLabel } = strapiNavData;
  return (
    <div className="flex items-center gap-4">
      <NavItem
        variant="dark"
        isNavLink={true}
        isExternal={true}
        to={INTERNAL_SITE_ROUTES.LOGIN}
        className="whitespace-nowrap rounded px-5 py-4 font-medium text-white"
      >
        {logInLabel}
      </NavItem>
      <NavItem
        variant="dark"
        isNavLink={true}
        isExternal={true}
        to={EXTERNAL_SITE_ROUTES.HQ_PLUS_PRICING_SECTION}
        className="whitespace-nowrap rounded bg-base-brand px-5 py-4 font-medium text-white shadow-[0px_4px_6px_-1px_rgba(0,_0,_0,_0.1),_0px_2px_4px_-1px_rgba(0,_0,_0,_0.06)]"
      >
        {signUpLabel}
      </NavItem>
    </div>
  );
};

export default NavAuthActions;
