import { Link } from 'react-router-dom';

type props = {
  footer: any;
};

const FooterCard = ({ footer }: props) => {
  const footerHasMultipleLinks = footer.listItems && footer.listItems.length > 1;

  // wraps the footer links in a ul if there are multiple links and in a div if there is only one link
  const FooterLinksContainer = footerHasMultipleLinks ? 'ul' : 'div';
  // each link would be a li if there are multiple links and a div if there is only one link
  const FooterLinkContainer = footerHasMultipleLinks ? 'li' : 'div';

  return (
    <div className=" mt-4  h-fit w-full text-center">
      <div className="flex w-[8.68rem] flex-col gap-3  bg-[#18181B] text-left">
        <h2 className="text-base font-bold leading-7 text-zinc-100">{footer.footerCategory}</h2>
        <FooterLinksContainer className="text-base font-medium leading-6 text-zinc-100 opacity-50">
          {footer.listItems.map((listItems: any, index: any) => {
            return (
              <FooterLinkContainer key={index}>
                <Link to={listItems.footerListLink}>
                  <span>{listItems.footerListItems}</span>
                </Link>
              </FooterLinkContainer>
            );
          })}
        </FooterLinksContainer>
      </div>
    </div>
  );
};

export default FooterCard;
