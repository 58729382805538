import clsx from 'clsx';
import { List, X } from 'phosphor-react';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import DesktopNavMenu from './DesktopNavMenu';
import NavAuthActions from './NavAuthActions';
import NavbarMobileMenu from './NavbarMobileMenu';
import NavItem from './NavItem';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import { useMediaQuery } from 'usehooks-ts';

interface Props {
  navClassName?: string;
  variant?: 'light' | 'dark';
  className?: string;
  showAuthActions?: boolean;
  showUSHGLogo?: boolean;
}

const Navbar = (props: Props) => {
  const {
    className,
    navClassName,
    variant = 'dark',
    showAuthActions = false,
    showUSHGLogo = true,
  } = props;

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // strapi
  const data = useStrapiDataHelper();
  const {
    mobileHamburgerMenuSRLabel,
    hqHomeLinkSRLabel,
    ushgHomeLinkSRLabel,
    USHGLogoLight,
    USHGLogoDark,
    HQLogoLight,
    HQLogoDark,
  } = data.nav.data.attributes;

  // strapi

  const toggleMobileNavMenu = () => {
    setShowMobileMenu((show) => !show);
  };

  const USHGLogo = variant == 'dark' ? USHGLogoDark : USHGLogoLight;
  const HQLogo = variant == 'dark' ? HQLogoDark : HQLogoLight;

  // use appropriate icon for the mobile menu icon based on its state
  const HamburgerIcon = showMobileMenu ? X : List;
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');

  return (
    <div
      className={twMerge(
        clsx(
          'relative z-10 flex min-h-[111px] w-full flex-row items-center justify-between px-9 py-2.5',
          className
        )
      )}
    >
      {/* Hospitality Quotient Logo and Hamburger Menu */}
      <div className="flex items-center gap-4">
        <button
          className="w-max lg:hidden"
          onClick={toggleMobileNavMenu}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          onTouchStart={(event) => {
            event.stopPropagation();
          }}
        >
          <span className="sr-only">{mobileHamburgerMenuSRLabel}</span>
          <HamburgerIcon
            className={clsx(
              'block',
              variant === 'light' && 'text-zinc-900',
              variant === 'dark' && 'text-zinc-300'
            )}
            size={24}
            color="currentColor"
            aria-hidden="true"
          />
        </button>
        <NavItem to="/" isExternal={false} isNavLink={true}>
          <span className="sr-only">{hqHomeLinkSRLabel}</span>
          <img
            style={{
              height: `${HQLogo.Height}px`,
              width: `${HQLogo.Width}px`,
              objectFit: 'contain',
            }}
            src={HQLogo.Logo.data.attributes.url}
            alt={HQLogo.Logo.data.attributes.alternativeText}
          />
        </NavItem>
      </div>
      {/* Nav Items */}
      {isLargeScreen ? (
        <div className="absolute left-1/2 -translate-x-1/2">
          <DesktopNavMenu className={clsx('relative', navClassName)} variant={variant} />
        </div>
      ) : null}
      {showMobileMenu && (
        <NavbarMobileMenu
          // variant={variant}
          // TODO: For now let this be dark for all pages, suggested by @PraveenJuge
          variant={'light'}
          className={clsx('block lg:hidden', navClassName)}
          setShowMobileMenu={setShowMobileMenu}
        />
      )}
      {/* USHG Logo and Auth Actions */}
      {showUSHGLogo && (
        <NavItem to="https://www.ushg.com/" isExternal={true}>
          <span className="sr-only">{ushgHomeLinkSRLabel}</span>
          <img
            style={{
              height: `${USHGLogo.Height}px`,
              width: `${USHGLogo.Width}px`,
              objectFit: 'contain',
            }}
            src={USHGLogo.Logo.data.attributes.url}
            alt={USHGLogo.Logo.data.attributes.alternativeText}
          />
        </NavItem>
      )}
      {showAuthActions && <NavAuthActions />}
    </div>
  );
};

Navbar.NavItem = NavItem;

export default Navbar;
