import clsx from 'clsx';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
}

const CLPHeroIllustration = ({ className }: Props) => {
  const { landingPage } = useStrapiCLPData();
  // Extract url and alternativeText, providing defaults to avoid errors
  const imageSrc = landingPage?.CoverImage?.data?.attributes?.url || '';
  const alternativeText = landingPage?.CoverImage?.data?.attributes?.alternativeText || '';

  return <img src={imageSrc} className={clsx(className)} alt={alternativeText}></img>;
};

export default CLPHeroIllustration;
