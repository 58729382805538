import { gql } from '@apollo/client';

export const SharedFragment = gql`
  fragment SharedFragment on SharedEntityResponse {
    data {
      attributes {
        contactUsEmail
        skipToMainContentLinkLabel
        PageNotFound {
          title
          subTitle
          homepageLabel
          goBackLabel
          orLabel
          Seo {
            pageTitle
            pageDescription
            ogTitle
            ogDescription
            ogSiteName
            canonicalUrl
            twitterTitle
            twitterDescription
            twitterUsername
            socialMediaImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            ogType
            twitterCardType
            preventIndexing
          }
        }
        Dialog {
          closeSRLabel
        }
        Icon {
          calendarSRLabel
          mapPinSRLabel
          starSRLabel
          clockSRLabel
          notebookSRLabel
        }
        ApplicationLevelErrorBoundary {
          title
          tryAgainLabel
          goBackLabel
        }
        images {
          brandLogoGridPatternImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;
