import React from 'react';
import PatternIllustrator from './PatternIllustrator';
import BlobIllustrator from './BlobIllustrator';
type CustomProps = {
  className?: string;
  showIllustrator?: boolean;
  children: React.ReactNode | JSX.Element[];
};
export const Page = ({ className, children, showIllustrator = false }: CustomProps) => {
  return (
    <div
      className={`relative flex h-screen  w-screen flex-col overflow-hidden bg-page-bg-dark ${
        className ?? ''
      }`}
    >
      <div className="z-20 flex flex-col">{children}</div>
      {showIllustrator && (
        <>
          <BlobIllustrator />
          <PatternIllustrator />
        </>
      )}
    </div>
  );
};
