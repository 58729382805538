import { gql } from '@apollo/client';

export const CombinedLandingPageFragment = gql`
  fragment CombinedLandingPageFragment on CombinedLandingPageEntityResponse {
    data {
      attributes {
        OurServices {
          title
          description
          OurServiceTitle
          Services {
            title
            description
            comingSoonButton
            slug
            theme
            Icon
            serviceImage {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
            button
            buttonSRLabel
            link
            isActive
            isExternalSite
          }
        }
        FeatureFlag {
          showHQPlus
          lastServiceCardTakesFullWidth
          showAboutUs
        }
        AboutHQ {
          WhatIsHQ {
            description
            title
          }
          GoldenRules {
            title
            description
            Rules {
              rule
            }
          }
          CoverImage {
            data {
              attributes {
                url
              }
            }
          }
        }
        WhyHQ {
          title
          WhyUsCard {
            question
            cardTheme
          }
          answer
          GetInTouch {
            label
            isActive
            isExternal
            link
            slug
          }
          backgroundImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
        NewsletterSection {
          title
          description
          button {
            label
            isActive
            isExternal
            link
            slug
          }
        }

        LandingPage {
          Slogan {
            mantra
          }
          Title {
            label
            color
          }
          CoverImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          description
          Buttons {
            slug
            isActive
            link
            isExternal
            label
          }
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
