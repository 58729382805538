import * as Tooltip from '@radix-ui/react-tooltip';
import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaVolumeRange,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaMuteButton,
  MediaPipButton,
  MediaFullscreenButton,
  MediaLoadingIndicator,
  MediaCaptionsButton,
  MediaPlaybackRateButton,
} from 'media-chrome/dist/react';
import { useState } from 'react';
import VideoControlTooltipWrapper from './VideoControlTooltipWrapper';

type VideoProps = {
  className?: string;
  thumbnail: string | undefined;
  video?: string;
  onEndHandler?: () => void;
  beforeEndHandler?: () => void;
  settings?: {
    autoplay: boolean;
    muted: boolean;
    loop: boolean;
  };
};

const VideoController = ({
  className,
  thumbnail,
  video,
  onEndHandler,
  beforeEndHandler,
  settings,
}: VideoProps) => {
  const [beforeEndCalled, setBeforeEndCalled] = useState<boolean>(false);

  const videoPlayingHandler = (event: any) => {
    const { currentTime, duration } = event.target;
    if (duration - currentTime <= 20) {
      if (!beforeEndCalled && beforeEndHandler) {
        beforeEndHandler();
        setBeforeEndCalled(true);
      }
    }
  };

  const videoEndedHandler = (_event: any) => {
    if (onEndHandler) {
      onEndHandler();
    }
  };
  return (
    <Tooltip.Provider>
      {thumbnail && !video && (
        <div
          className={`aspect-video h-auto w-full max-w-full overflow-hidden bg-black object-contain lg:mr-auto  lg:shrink lg:grow ${
            className ?? ''
          }`}
        >
          <img className="h-full w-full object-cover" alt="Course Thumbnail" src={thumbnail}></img>
        </div>
      )}
      {video && (
        <MediaController
          video
          className={`aspect-video h-auto w-full max-w-full overflow-hidden bg-black object-contain lg:mr-auto  lg:shrink lg:grow ${
            className ?? ''
          }`}
        >
          <video
            playsInline
            slot="media"
            src={video}
            onTimeUpdate={videoPlayingHandler}
            onEnded={videoEndedHandler}
            poster={thumbnail}
            autoPlay={settings?.autoplay}
            muted={settings?.muted}
            loop={settings?.loop}
            preload="auto"
            className="h-full w-full object-contain"
          >
            {/* <track
            label="thumbnails"
            default
            kind="metadata"
            src="https://image.mux.com/DS00Spx1CV902MCtPj5WknGlR102V5HFkDe/storyboard.vtt"
          ></track>
          <track
            label="English"
            kind="captions"
            srcLang="en"
            src="https://media-chrome.mux.dev/examples/vtt/en-cc.vtt"
            ></track> */}
          </video>
          <MediaLoadingIndicator
            slot="centered-chrome"
            className="hidden lg:block"
            isLoading
          ></MediaLoadingIndicator>

          <div slot="centered-chrome" className="flex w-full items-center justify-center lg:hidden">
            <MediaSeekBackwardButton className="m-[5%] w-[10%] bg-transparent p-0"></MediaSeekBackwardButton>
            <MediaPlayButton className="m-[5%] w-[15%] bg-transparent p-0"></MediaPlayButton>
            <MediaSeekForwardButton className="m-[5%] w-[10%] bg-transparent p-0"></MediaSeekForwardButton>
          </div>

          {/* Layout control elements in single bar */}
          <MediaControlBar className="h-8 lg:hidden">
            <MediaTimeRange className="h-auto bg-transparent"></MediaTimeRange>
            <MediaTimeDisplay className="bg-transparent" showDuration></MediaTimeDisplay>
          </MediaControlBar>

          {/* Layout control elements in single bar */}
          <MediaControlBar className="h-9 lg:h-11">
            <VideoControlTooltipWrapper content={'Play'}>
              <MediaPlayButton className="hidden bg-gray-900/60 p-1.5 lg:block lg:p-2.5"></MediaPlayButton>
            </VideoControlTooltipWrapper>
            <VideoControlTooltipWrapper content={'Mute/Unmute'}>
              <MediaMuteButton
                className="bg-gray-900/60 p-1.5 lg:p-2.5"
                mediaVolumeLevel="medium"
              ></MediaMuteButton>
            </VideoControlTooltipWrapper>

            <MediaVolumeRange className="h-auto bg-gray-900/60 p-1.5 lg:p-2.5"></MediaVolumeRange>
            <VideoControlTooltipWrapper content={'Rewind 30s'}>
              <MediaSeekBackwardButton className="hidden bg-gray-900/60 p-1.5 lg:block lg:p-2.5"></MediaSeekBackwardButton>
            </VideoControlTooltipWrapper>
            <VideoControlTooltipWrapper content={'Forward 30s'}>
              <MediaSeekForwardButton className="hidden bg-gray-900/60 p-1.5 lg:block lg:p-2.5"></MediaSeekForwardButton>
            </VideoControlTooltipWrapper>
            <div className="grow bg-gray-900/60 lg:hidden"></div>
            <MediaTimeRange className="hidden h-auto bg-gray-900/60 p-1.5 lg:block lg:p-2.5"></MediaTimeRange>
            <MediaTimeDisplay
              className="hidden bg-gray-900/60 lg:block"
              showDuration
            ></MediaTimeDisplay>
            <VideoControlTooltipWrapper content="Playback rate">
              <MediaPlaybackRateButton className="self-center bg-gray-900/60 p-1.5 lg:p-2.5"></MediaPlaybackRateButton>
            </VideoControlTooltipWrapper>
            <VideoControlTooltipWrapper content="Captions">
              <MediaCaptionsButton
                mediaCaptionsShowing="en:English%20Closed%20Captions"
                mediaCaptionsList="en:English%20Closed%20Captions"
                className="bg-gray-900/60 p-1.5 lg:p-2.5"
              ></MediaCaptionsButton>
            </VideoControlTooltipWrapper>
            <VideoControlTooltipWrapper content="Pip Mode">
              <MediaPipButton className="bg-gray-900/60 p-1.5 lg:p-2.5"></MediaPipButton>
            </VideoControlTooltipWrapper>
            <VideoControlTooltipWrapper content="Fullscreen">
              <MediaFullscreenButton className="bg-gray-900/60 p-1.5 lg:p-2.5"></MediaFullscreenButton>
            </VideoControlTooltipWrapper>
          </MediaControlBar>
        </MediaController>
      )}
    </Tooltip.Provider>
  );
};

export default VideoController;
